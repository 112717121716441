import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';

const logDebug = debug(DebugLogsNamespace.AppDebug);
const logError = debug(DebugLogsNamespace.AppErrors);

export function saveLog(type: 'error' | 'info', message: string): void {
  const logger = type === 'error' ? logError : logDebug;
  logger(message);
}
