import * as Sentry from '@sentry/browser';
import TraceKit from 'tracekit';

import { App } from 'config/setup';
import ChatWidget from 'services/chat-widget';
import { enableBusinessEventsLogging } from 'services/event-tracking';

/**
 * This method requires store to be initialized before running.
 * You can still intentionally run it without initialized store
 * but the tracked data will be incomplete (e.g. no agent's email value).
 * Example cases for incomplete data: error pages, logout screen.
 */
export function initializeTrackingTools(): void {
  enableBusinessEventsLogging();

  TraceKit.report.subscribe((stackTrace, isWindowError, error) => {
    Sentry.captureException(error, {
      tags: {
        tracekit: true,
      },
      extra: {
        stackTrace,
        isWindowError,
      },
    });
  });

  ChatWidget.setUpCallbacks();

  App.initGoogleAnalytics?.();
  App.setupGTM?.();
  App.setupAmplitudeUserProperties?.();
}
