// @ts-strict-ignore
/* eslint-disable @typescript-eslint/no-unsafe-return */
import debug from 'debug';
import map from 'lodash.map';

import { App } from 'config/setup';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { type PlanType } from 'constants/plan-type';
import { QueryKey } from 'constants/query-key';
import { Route } from 'constants/tickets/navigation-items';
import type { ITicket } from 'interfaces/entities/ticket';
import { calculateFirstChat } from 'services/activity/helpers/calculate-first-chat';
import { AppStateProvider } from 'services/app-state-provider';
import { updateFirstChatDate } from 'services/chat-widget';
import { BillingProperty, StatisticsProperty } from 'services/connectivity/configuration-api/properties/constants';
import { getQueryClient } from 'services/query-client/client';
import { IncomingMulticastEventName } from 'services/socket-lc3/chat/event-handling/constants';
import { handleJSONIncomingEvent as handleLC3JSONIncomingEvent } from 'services/socket-lc3/socket-event-handler';
import { ApplicationsActions } from 'store/features/applications/actions';
import { SessionActions } from 'store/features/session/actions';
import { TicketsViewActions } from 'store/views/tickets/actions';
import type { ITicketsViewUpdateTicketCountersPayload } from 'store/views/tickets/interfaces';
import { TrafficActions } from 'store/views/traffic/actions';

const log = debug(DebugLogsNamespace.AppWebSocket);

export class JsonProtocol {
  performCommand(response): void {
    log(`Incoming command ${response.name}`);

    handleLC3JSONIncomingEvent(response.name, response);

    switch (response.name) {
      case IncomingMulticastEventName.AccountBlocked:
        this.accountBlocked();
        break;
      case IncomingMulticastEventName.IntegrationEnable:
        this.integrationEnable(response.integration);
        break;
      case IncomingMulticastEventName.IntegrationDisable:
        this.integrationDisable(response.integration);
        break;
      case IncomingMulticastEventName.TicketUpdate:
        JsonProtocol.ticketUpdate(response.tickets);
        break;
      case IncomingMulticastEventName.TicketSummaryUpdate:
        JsonProtocol.ticketSummaryUpdate(response);
        break;
      case IncomingMulticastEventName.LicensePropertiesUpdate:
        this.licensePropertiesUpdate(response.properties);
        break;
      case IncomingMulticastEventName.BanListUpdate:
        void JsonProtocol.banListUpdated();
        break;
    }
  }

  accountBlocked(): void {
    void App.destroy({ licenseBlocked: true });
    App.logout();
  }

  integrationEnable(integrationJSON) {
    AppStateProvider.dispatch(ApplicationsActions.installApplicationSuccessful(integrationJSON));
  }

  integrationDisable(integrationJSON) {
    AppStateProvider.dispatch(ApplicationsActions.uninstallApplicationSuccessful({ id: integrationJSON.id }));
  }

  decodePrechatSurvey(prechat) {
    if (prechat.decodedAlready) {
      return prechat;
    }

    return map(prechat, function (fieldObject: { value: string }) {
      fieldObject.value = (function () {
        try {
          return decodeURIComponent(fieldObject.value);
        } catch (error) {
          return fieldObject.value;
        }
      })();

      return fieldObject;
    });
  }

  static ticketUpdate(ticketsJSON: ITicket[]): void {
    AppStateProvider.dispatch(TicketsViewActions.onTicketsUpdated(ticketsJSON.map((ticket) => ticket.id)));
  }

  static ticketSummaryUpdate(response): void {
    if (response.unassigned_tickets != null || response.open_tickets != null) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const summary: { unassigned_tickets?: number; open_tickets?: number } = {};
      const actionPayload: ITicketsViewUpdateTicketCountersPayload = {};
      if (response.unassigned_tickets != null) {
        summary.unassigned_tickets = response.unassigned_tickets;
        actionPayload[Route.Unassigned] = response.unassigned_tickets;
      }
      if (response.open_tickets != null) {
        summary.open_tickets = response.open_tickets;
        actionPayload[Route.MyOpen] = response.open_tickets;
      }

      AppStateProvider.dispatch(TicketsViewActions.updateTicketCounters(actionPayload));
    }
  }

  licensePropertiesUpdate(properties) {
    // Configuration API does not support push events for license properties, therefore, we're still using
    // legacy REST API push implementation to save sales plan license property when it's updated
    // TODO: move these updates to the new "license property updated" push that will be created by api team at some point
    const salesPlanLicenseProperty: PlanType = properties[BillingProperty.SalesPlan];

    if (salesPlanLicenseProperty) {
      AppStateProvider.dispatch(SessionActions.saveLicensePlan(salesPlanLicenseProperty));
    }

    const firstChatDate = properties[StatisticsProperty.FirstChatDate];

    if (firstChatDate) {
      updateFirstChatDate(calculateFirstChat(firstChatDate));
    }
  }

  static async banListUpdated(): Promise<void> {
    const queryClient = getQueryClient();
    // This could be changed to invalidateQueries when we fully switch to react
    // at this moment we are using queryCache to gat data in backbone views and models
    // so it's necessary to trigger refetch here (in react we can use hooks that subscribe for changes by design)
    await queryClient.refetchQueries([QueryKey.BannedCustomers]);

    AppStateProvider.dispatch(TrafficActions.bannedCustomersListUpdate());
  }
}
