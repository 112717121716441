import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';

import { getReconnector } from './service';
import { ReconnectionStatus } from './types';

const OFFLINE_DURATION_BEFORE_RECONNECT = 30000;
export const DEFAULT_RECONNECT_RETRY_COUNT = 10;

const log = debug(DebugLogsNamespace.AppServerConnection);

/**
 * Attaches connection status event listeners.
 */
export function attachConnectionStatusEventListeners(): void {
  const reconnector = getReconnector();
  let offlineTimestamp: number | null = null;

  reconnector.subscribeToOnlineChange(handleOnlineChange);

  /**
   * Handles the change in online status.
   *
   * @param isOnline - Whether the service is currently online.
   */
  function handleOnlineChange(isOnline: boolean): void {
    if (!isOnline) {
      handleOffline();
    } else {
      handleOnline();
    }
  }

  /**
   * Handles logic when the service goes offline.
   */
  function handleOffline(): void {
    offlineTimestamp = Date.now();
    reconnector.stop();
  }

  /**
   * Handles logic when the service comes back online.
   */
  function handleOnline(): void {
    if (reconnector.isHandlingReconnection) {
      reconnector.restart();
    } else if (offlineTimestamp !== null) {
      checkOfflineDurationAndReconnect();
    }
  }

  /**
   * Checks how long the service was offline and decides whether to reconnect.
   */
  function checkOfflineDurationAndReconnect(): void {
    if (offlineTimestamp !== null) {
      const timeOffline = Date.now() - offlineTimestamp;
      offlineTimestamp = null;

      if (timeOffline > OFFLINE_DURATION_BEFORE_RECONNECT) {
        log('Reconnecting due to offline threshold exceeded');
        void reconnector.reconnect({
          reason: 'offline-threshold-exceeded',
          maxAttempts: DEFAULT_RECONNECT_RETRY_COUNT,
        });
      } else {
        log('Connection was offline for less than threshold, not reconnecting');
        reconnector.setReconnectionDetails({
          status: ReconnectionStatus.Idle,
          nextReconnectAttemptTimestamp: null,
        });
      }
    }
  }
}
