import debug from 'debug';

import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { redirectToNetworkIssuesError } from 'helpers/routing';
import { getReconnector } from 'services/connectivity/reconnector/service';

const log = debug(DebugLogsNamespace.AppServerConnection);

export function handleNetworkIssuesError(error: unknown, isReconnect: boolean): void {
  log('handleNetworkIssuesError', error);

  getReconnector().loginFailed();

  if (isReconnect) {
    log(
      'Network issue detected while reconnecting - any further reconnections should be handled by reconnector or connectivity status client',
    );

    return;
  }

  log('Redirecting to network issues page', { error });
  redirectToNetworkIssuesError(error);
}
