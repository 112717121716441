import { type IncomingMessage } from 'interfaces/incoming-message';
import { getReconnector } from 'services/connectivity/reconnector/service';
import { type ReconnectionDetails, ReconnectionStatus } from 'services/connectivity/reconnector/types';
import { getWebsocketParser } from 'services/server/get-websocket-parser';
import { ServerAccessPlatform } from 'services/server/server-access-platform';
import { setWebsocketConnection } from 'services/server/websocket-connection';

interface InitializeServerOptions {
  compressWebsocket: boolean;
}

export function initializeServer({ compressWebsocket }: InitializeServerOptions): void {
  const reconnector = getReconnector();
  const parser = getWebsocketParser();
  const server = new ServerAccessPlatform(compressWebsocket);

  server.on('message', (message: IncomingMessage) => {
    if (reconnector.getReconnectionDetails().status === ReconnectionStatus.Idle) {
      parser.parse(message);
    } else if (reconnector.isReconnecting) {
      /**
       * This is to buffer messages that were ignored during the reconnect process.
       * Sometimes, after a successful login, we may receive immediate messages from WebSocket
       * but the state of `Reconnector` is still "reconnecting".
       * We are buffering such messages and parsing them after a successful reconnect.
       */
      parser.addMessageToBuffer(message);
    }
  });

  reconnector.subscribeToReconnectionDetailsChange((details: ReconnectionDetails) => {
    if (details.status === ReconnectionStatus.Succeeded) {
      parser.flushMessagesBuffer();
    }
  });

  setWebsocketConnection(server);
}
