import { useCallback, useEffect, type FC, type PropsWithChildren } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { TrackingEvent } from 'components/shortcuts/constants';
import { CopilotTrackEvent } from 'constants/copilot-event';
import { GlobalModal } from 'constants/global-modal';
import { KeyEnum } from 'constants/key-code';
import { SPOTLIGHT_MEDIA_QUERY, SpotlightTrackEvent } from 'constants/spotlight';
import { EventPlace } from 'helpers/analytics';
import { isMacOS, isPercyUserAgent } from 'helpers/browser';
import { shouldForceDesktopView } from 'helpers/device';
import { trackEventOncePerUniqueKey } from 'helpers/event-saver';
import { getIsInputFocused } from 'helpers/focused-element';
import { navigate } from 'helpers/routing';
import { isSpotlightEnabled } from 'helpers/spotlight';
import { useEffectOnce } from 'hooks/use-effect-once';
import { trackEvent } from 'services/event-tracking';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { CopilotViewActions } from 'store/views/copilot/actions';
import { getCanUseCopilotPopover } from 'store/views/copilot/selectors';
import { getIsOnboardingFinished } from 'store/views/onboarding/selectors';

/**
 * this handler takes care of manually rendered <a> elements that omit react routing handlers
 * otherwise we would reload the page on click
 */
function handleBodyClick(event: MouseEvent): void {
  // don't navigate when already navigating or event is handled another way
  const isAlreadyHandled = event.defaultPrevented;
  if (isAlreadyHandled) {
    return;
  }

  // check if <a> element was clicked
  const anchor = (event.target as HTMLElement)?.closest('a');
  if (anchor) {
    // legacy views use this manual "prevent navigation" mechanism
    const isNavigationBlockedManually = anchor.getAttribute('data-navigate') === '0';
    const hasDifferentOrigin = anchor.origin !== window.location.origin;
    const isOpeningInNewTab = event.ctrlKey || event.metaKey || event.shiftKey || anchor.target === '_blank';
    const isMailto = anchor.href.startsWith('mailto:');

    const preventNavigation = isNavigationBlockedManually || hasDifferentOrigin || isOpeningInNewTab || isMailto;

    if (!preventNavigation) {
      event.preventDefault();
      navigate(anchor.pathname + anchor.search + anchor.hash);
    }
  }
}

export const GlobalDOMHandlers: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const isOnboardingFinished = useSelector(getIsOnboardingFinished);
  const isCopilotEnabled = useSelector(getCanUseCopilotPopover);

  const dispatch = useDispatch();

  const handleKeyDown = useCallback(
    (event: KeyboardEvent): void => {
      if (!isOnboardingFinished) {
        return;
      }

      const isInputFocused = getIsInputFocused();
      const osFirstKey = isMacOS() ? event.metaKey : event.ctrlKey;

      if (event.shiftKey && (event.code as KeyEnum) === KeyEnum.Slash && !isInputFocused) {
        dispatch(GlobalModalActions.showModal(GlobalModal.KeyboardShortcuts));
        trackEventOncePerUniqueKey(
          TrackingEvent.ShortcutUsed,
          TrackingEvent.ShortcutUsed,
          EventPlace.KeyboardShortcuts,
          {
            shortcut: ['shiftKey', event.code].join(' + '),
          },
        );
      }

      const isSpotlightVisible = window.matchMedia(`(min-width: ${SPOTLIGHT_MEDIA_QUERY}px)`).matches;

      const shouldOpenSpotlightModal = isSpotlightEnabled && isSpotlightVisible && osFirstKey && event.key === 'k';

      if (shouldOpenSpotlightModal) {
        event.preventDefault();
        dispatch(GlobalModalActions.showModal(GlobalModal.Spotlight));
        trackEvent(SpotlightTrackEvent.SpotlightTrigger, null, {
          source: 'keyboard shortcut',
        });
      }

      const shouldOpenCopilotModal = isCopilotEnabled && osFirstKey && event.key === 'g';

      if (shouldOpenCopilotModal) {
        event.preventDefault();
        dispatch(CopilotViewActions.toggleModal());
        trackEvent(CopilotTrackEvent.ChatOpened, EventPlace.One, { type: 'shortcut' });
      }
    },
    [dispatch, isCopilotEnabled, isOnboardingFinished],
  );

  useEffectOnce(() => {
    if (isPercyUserAgent()) {
      document.body.classList.add('no-animations');
    }

    if (shouldForceDesktopView()) {
      document.body.classList.add('force-desktop-view');
    }

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  });

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return <>{children}</>;
};
