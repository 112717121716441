// @ts-strict-ignore
/* eslint-disable @typescript-eslint/naming-convention */
import * as Sentry from '@sentry/browser';
import debug from 'debug';

import botEngineLogo from 'assets/img/integrations/botengine-app-logo.png';
import { DebugLogsNamespace } from 'constants/debug-logs-namespace';
import { GlobalModal } from 'constants/global-modal';
import { LC3IncomingEvent } from 'constants/lc3-incoming-event';
import { LoginStatus } from 'constants/login-status';
import { TopBarNotificationType } from 'constants/notifications';
import { redirectToAccountsSignout } from 'helpers/redirect-to-accounts';
import { redirectToLicenseExpired, redirectToSignOut, redirectToSubscription } from 'helpers/routing';
import type { IArchive } from 'interfaces/entities/archive';
import type { IncomingMessage } from 'interfaces/incoming-message';
import { SignoutReason } from 'interfaces/session';
import type { GroupRTMDTO } from 'services/api/group/interfaces';
import { deserializeGroup, deserializePartialGroup } from 'services/api/group/serializer';
import { AppStateProvider } from 'services/app-state-provider';
import { getRegion } from 'services/auth/auth-storage-manager';
import {
  DEFAULT_RECONNECT_RETRY_COUNT,
  attachConnectionStatusEventListeners,
} from 'services/connectivity/reconnector/events';
import { getReconnector } from 'services/connectivity/reconnector/service';
import { saveLog } from 'services/save-log';
import { removeSessionAndRedirectOut } from 'services/session';
import {
  AgentDisconnectedReason,
  type IAgentDisconnectedMisdirectedConnectionReasonPushEvent,
  type IAgentDisconnectedMisdirectedRequestReasonPushEvent,
  type IAgentDisconnectedPushEvent,
} from 'services/socket-lc3/agent/interfaces';
import { partialDeserialize as partialDeserializeAgent } from 'services/socket-lc3/agent/serialization/agent-serializer';
import { IncomingMulticastIwcsCommand } from 'services/socket-lc3/chat/event-handling/constants';
import {
  isMulticastBotEnginePayload,
  isMulticastInsightsPayload,
  isMulticastLc2IwcsPayload,
  isMulticastLc2Payload,
} from 'services/socket-lc3/chat/event-handling/helpers/incoming-multicast';
import {
  type IIncomingMulticastBotEnginePayload,
  type IIncomingMulticastInsightsPayload,
  type IIncomingMulticastLC2Payload,
  type IIncomingMulticastLc2IwcsPayload,
  type IThreadTaggedPushEvent,
  type IThreadUntaggedPushEvent,
  type IncomingMulticastPayload,
} from 'services/socket-lc3/chat/interfaces';
import { handleLC3IncomingEvent } from 'services/socket-lc3/socket-event-handler';
import { websocketEventsHistoryManager } from 'services/web-sockets-events-history-manager';
import { AgentActions } from 'store/entities/agents/actions';
import { getLoggedInAgent, getLoggedInAgentLogin } from 'store/entities/agents/selectors';
import { ArchiveActions } from 'store/entities/archives/actions';
import { BadgeActions } from 'store/entities/badges/actions';
import { BadgeKey } from 'store/entities/badges/interfaces';
import { getBot } from 'store/entities/bots/selectors';
import { GroupActions } from 'store/entities/groups/actions';
import { GlobalModalActions } from 'store/features/global-modals/actions';
import { NotificationsBarActions } from 'store/features/notifications-bar/actions';
import { getCanManageSubscription } from 'store/features/session/selectors';
import { stopAppSagas } from 'store/sagas/app-sagas';
import { getArchive } from 'store/views/archives/selectors';
import { CopilotViewActions } from 'store/views/copilot/actions';

import { reauthorizeAndReinitialize } from '../reauthorize';
import { getWebsocketConnection } from '../websocket-connection';

import { JsonProtocol } from './json-protocol';

const log = debug(DebugLogsNamespace.AppWebSocket);

export class PlatformProtocolParser {
  jsonProtocol: JsonProtocol;
  inited = false;

  private messagesBuffer: IncomingMessage[] = [];

  constructor() {
    attachConnectionStatusEventListeners();

    this.jsonProtocol = new JsonProtocol();
  }

  addMessageToBuffer(message: IncomingMessage): void {
    this.messagesBuffer.push(message);
  }

  flushMessagesBuffer(): void {
    this.messagesBuffer.forEach((message) => this.parse(message));
    this.messagesBuffer = [];
  }

  parse(message: IncomingMessage): void {
    log(`Incoming message: ${message.action}`, message.payload);

    websocketEventsHistoryManager.addWebSocketEvent({
      name: message.action,
      type: message.type,
      request_id: message.type === 'response' ? message.request_id : undefined,
      payload: message.payload,
    });

    if (message.type === 'response') {
      return;
    }

    handleLC3IncomingEvent(message.action, message.payload, message.version);

    // only messages with type === 'push'
    switch (message.action) {
      case LC3IncomingEvent.AgentDisconnected:
        this.agentDisconnected(message.payload);
        break;

      case LC3IncomingEvent.GroupCreated:
        this.groupCreated(message.payload);
        break;

      case LC3IncomingEvent.GroupUpdated:
        this.groupUpdated(message.payload);
        break;

      case LC3IncomingEvent.GroupDeleted:
        this.groupDeleted(message.payload);
        break;

      case LC3IncomingEvent.GroupsStatusUpdated:
        this.groupsStatusUpdated(message.payload);
        break;

      case LC3IncomingEvent.IncomingMulticast:
        this.incomingMulticast(message.payload);
        break;

      case LC3IncomingEvent.ThreadTagged:
        this.chatThreadTagged(message.payload);
        break;

      case LC3IncomingEvent.ThreadUntagged:
        this.chatThreadUntagged(message.payload);
        break;

      default:
        saveLog('info', `Platform protocol not supported: ${message.action}`);
        break;
    }
  }

  agentDisconnected(payload: IAgentDisconnectedPushEvent): void {
    AppStateProvider.dispatch(GlobalModalActions.hideAllModals());
    AppStateProvider.dispatch(CopilotViewActions.hideModal());

    switch (payload.reason) {
      case AgentDisconnectedReason.LicenseExpired:
        this.tryToReconnect(payload.reason, DEFAULT_RECONNECT_RETRY_COUNT, this.handleLicenseExpiredError);
        break;

      case AgentDisconnectedReason.LoggedOutRemotely:
        redirectToAccountsSignout(SignoutReason.SignedOutRemotely);
        break;

      case AgentDisconnectedReason.AgentDeleted:
        void removeSessionAndRedirectOut();
        break;

      case AgentDisconnectedReason.LicenseVersionChanged:
        redirectToAccountsSignout(SignoutReason.LicenseVersionChanged);
        break;

      case AgentDisconnectedReason.MisdirectedConnection:
      case AgentDisconnectedReason.MisdirectedRequest:
        this.handleMisdirectedConnection(payload);
        break;

      case AgentDisconnectedReason.AgentDisconnectedByServer:
      case AgentDisconnectedReason.ConnectionEvicted:
      case AgentDisconnectedReason.UnsupportedVersion:
      case AgentDisconnectedReason.LicenseNotFound:
      case AgentDisconnectedReason.AgentLoggedOutRemotely:
        this.closeConnectionAndStopSagas();
        redirectToSignOut(payload.reason);
        break;

      case AgentDisconnectedReason.InternalError:
      case AgentDisconnectedReason.ConnectionTimeout:
      case AgentDisconnectedReason.ServiceTemporarilyUnavailable:
      case AgentDisconnectedReason.TooManyConnections:
      case AgentDisconnectedReason.TooManyUnauthorizedConnections:
        this.tryToReconnect(payload.reason);
        break;

      case AgentDisconnectedReason.AccessTokenExpired:
      case AgentDisconnectedReason.PingTimeout:
      case AgentDisconnectedReason.AccessTokenRevoked:
      case AgentDisconnectedReason.RolePermissionChanged: {
        void reauthorizeAndReinitialize(payload.reason, payload.data?.cause);
        break;
      }

      default:
        this.handleUnknownReasonError(payload);
        break;
    }
  }

  groupCreated(payload): void {
    const deserializedGroup = deserializeGroup(payload);
    AppStateProvider.dispatch(GroupActions.groupAdded(deserializedGroup));

    if (Object.keys(deserializedGroup.agentsPriorities).length > 0) {
      Object.keys(deserializedGroup.agentsPriorities).forEach((login) => {
        AppStateProvider.dispatch(
          AgentActions.agentAddToGroup({
            login,
            groupId: deserializedGroup.id,
          }),
        );
      });
    }
  }

  groupUpdated(payload): void {
    AppStateProvider.dispatch(GroupActions.groupUpdated(deserializePartialGroup(payload)));
  }

  groupDeleted(payload): void {
    AppStateProvider.dispatch(GroupActions.groupRemoved(deserializePartialGroup(payload)));
  }

  groupsStatusUpdated({ groups }: { groups: GroupRTMDTO[] }): void {
    groups.forEach((group) => {
      AppStateProvider.dispatch(GroupActions.groupUpdated(deserializePartialGroup(group)));
    });
  }

  incomingMulticast(payload: IncomingMulticastPayload): void {
    if (isMulticastLc2Payload(payload)) {
      this.incomingLc2Multicast(payload);
    } else if (isMulticastLc2IwcsPayload(payload)) {
      this.incomingLc2IwcsMulticast(payload);
    } else if (isMulticastBotEnginePayload(payload)) {
      this.incomingBotEngineMulticast(payload);
    } else if (isMulticastInsightsPayload(payload)) {
      this.incomingInsightsMulticast(payload);
    }
  }

  incomingLc2Multicast(payload: IIncomingMulticastLC2Payload): void {
    this.jsonProtocol.performCommand(payload.content);
  }

  incomingLc2IwcsMulticast(payload: IIncomingMulticastLc2IwcsPayload): void {
    switch (payload.content.command) {
      case IncomingMulticastIwcsCommand.AgentStatusUpdate:
        this.incomingAgentMulticast(payload.content.agent);
        break;
    }
  }

  incomingBotEngineMulticast(payload: IIncomingMulticastBotEnginePayload): void {
    if (payload.author_id !== AppStateProvider.selectFromStore(getLoggedInAgentLogin)) {
      return;
    }

    const login = payload.content.bot_engine.bot_id;
    const botModel = AppStateProvider.selectFromStore(getBot, login);

    let botPayload = {
      name: 'ChatBot',
      avatarUrl: botEngineLogo,
    };

    if (botModel) {
      botPayload = {
        name: botModel.name,
        avatarUrl: botModel.avatar,
      };
    }

    if (payload.content.bot_engine.status === 'installed') {
      AppStateProvider.dispatch(GlobalModalActions.showModal(GlobalModal.ConnectChatbotSuccess));
    } else if (payload.content.bot_engine.status === 'enabled' && login) {
      AppStateProvider.dispatch(GlobalModalActions.showModal(GlobalModal.BotEngineEnabled, botPayload));
    } else if (payload.content.bot_engine.status === 'disabled' && login) {
      AppStateProvider.dispatch(GlobalModalActions.showModal(GlobalModal.BotEngineDisabled, botPayload));
    }
  }

  incomingInsightsMulticast(payload: IIncomingMulticastInsightsPayload): void {
    if (payload.author_id !== AppStateProvider.selectFromStore(getLoggedInAgentLogin)) {
      return;
    }
    const { overview, recentInsights, topCustomerQuestions } = payload.content.insights;
    AppStateProvider.dispatch(BadgeActions.setBadge(BadgeKey.InsightsOverview, overview));
    AppStateProvider.dispatch(BadgeActions.setBadge(BadgeKey.InsightsRecentInsights, recentInsights));
    AppStateProvider.dispatch(BadgeActions.setBadge(BadgeKey.InsightsTopCustomerQuestions, topCustomerQuestions));
  }

  incomingAgentMulticast(agent) {
    const deserializedAgent = partialDeserializeAgent(agent);
    const currentAgent = AppStateProvider.selectFromStore(getLoggedInAgent);
    AppStateProvider.dispatch(AgentActions.agentUpdate(deserializedAgent));

    if (
      !currentAgent ||
      currentAgent.login !== deserializedAgent.login ||
      currentAgent.status === deserializedAgent.status
    ) {
      return;
    }

    if (deserializedAgent.status === LoginStatus.Away) {
      AppStateProvider.dispatch(
        NotificationsBarActions.showNotificationsBar({ name: TopBarNotificationType.StatusAway }),
      );
    } else {
      AppStateProvider.dispatch(NotificationsBarActions.hideNotificationsBar(TopBarNotificationType.StatusAway));
    }
  }

  handleArchivedChatThreadTagged(archivedThread: IArchive, tag: string): void {
    AppStateProvider.dispatch(
      ArchiveActions.updateArchiveTagsSuccess({
        archiveId: archivedThread.id,
        tags: [...archivedThread.tags, tag],
      }),
    );
  }

  handleArchivedChatThreadUntagged(archivedThread: IArchive, tag: string): void {
    const updatedTags = archivedThread.tags.filter((singleTag) => singleTag !== tag);

    AppStateProvider.dispatch(
      ArchiveActions.updateArchiveTagsSuccess({
        archiveId: archivedThread.id,
        tags: updatedTags,
      }),
    );
  }

  chatThreadTagged(payload: IThreadTaggedPushEvent): void {
    // Specific chat model finder for history thread model
    const { thread_id, tag } = payload;
    const archivedThread = AppStateProvider.selectFromStore(getArchive, thread_id);

    if (archivedThread && !archivedThread?.tags.includes(tag)) {
      this.handleArchivedChatThreadTagged(archivedThread, tag);
    }
  }

  chatThreadUntagged(payload: IThreadUntaggedPushEvent): void {
    // Specific chat model finder for history thread model
    const { thread_id, tag } = payload;
    const archivedThread = AppStateProvider.selectFromStore(getArchive, thread_id);

    if (archivedThread && archivedThread?.tags.includes(tag)) {
      this.handleArchivedChatThreadUntagged(archivedThread, tag);
    }
  }

  closeConnectionAndStopSagas(): void {
    getWebsocketConnection().disconnect();
    stopAppSagas();
  }

  handleLicenseExpiredError(): void {
    const canManageSubscription = AppStateProvider.selectFromStore(getCanManageSubscription);

    if (canManageSubscription) {
      AppStateProvider.dispatch({ type: 'APP_ERROR' });
      redirectToSubscription();
    } else {
      redirectToLicenseExpired();
    }

    getWebsocketConnection().disconnect();
  }

  tryToReconnect(
    reason: AgentDisconnectedReason,
    maxAttempts = DEFAULT_RECONNECT_RETRY_COUNT,
    onFail?: () => void,
  ): void {
    // if "onFail" was not passed then manual reconnect button will be displayed
    // when reconnector failed to reconnect
    void getReconnector().reconnect({ reason, maxAttempts, onFail });
  }

  handleMisdirectedConnection(
    payload:
      | IAgentDisconnectedMisdirectedConnectionReasonPushEvent
      | IAgentDisconnectedMisdirectedRequestReasonPushEvent,
  ): void {
    const { region } = payload.data;
    Sentry.captureException(
      new Error(`Misdirected agent disconnected. Region switch from ${getRegion()} to ${region}`),
    );
  }

  handleUnknownReasonError(payload: IAgentDisconnectedPushEvent): void {
    Sentry.captureException(new Error(`Agent disconnected with unknown reason (payload=${JSON.stringify(payload)})`));

    this.closeConnectionAndStopSagas();

    redirectToSignOut(payload.data?.cause);
  }
}
